
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React, { useEffect, useRef, useState } from "react";
import { getResourceCollection } from "next-drupal";
import Layout from "components/Layout";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import { getGlobals } from "lib/getGlobals";
import Meta from "components/Meta";
import { formatDateYMD } from "lib/formatDate";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";

const SearchForm = dynamic(() => import("components/Search/SearchForm"));
const QuickAccessMenu = dynamic(() =>
  import("components/Menu/QuickAccessMenu")
);
const HomepageNews = dynamic(() => import("components/News/HomepageNews"));
const HomepageEvent = dynamic(() => import("components/Event/HomepageEvent"));
const HomepagePublication = dynamic(() =>
  import("components/Publication/HomepagePublication")
);

export default function IndexPage({ nodes, globals }) {
  const { t } = useTranslation("home");
  const nextRef = useRef();
  const prevRef = useRef();
  const [swiper, setSwiper] = useState();

  const hasNews = nodes.newses?.length > 0;
  const hasEvents = nodes.events?.length > 0;
  const hasPublications = nodes.publications?.length > 0;
  const hasGalleries = nodes.galleries?.length > 0;

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  return (
    <Layout globals={globals} banners={nodes.banners} home>
      <Meta title={t("meta.title")} description={t("meta.description")}/>
      <section id="quick-access" className="bg-secondary-dark">
        <div className="relative py-10 lg:-mt-40 lg:w-2/3 mx-auto bg-secondary-light">
          <div className="relative container mx-auto px-4">
            <div className="mb-12 w-4/5 lg:w-2/3 mx-auto">
              <SearchForm closeSearch={() => undefined} />
            </div>
            <QuickAccessMenu menu={globals.menus.quickAccess} />
          </div>
        </div>
      </section>
      {hasNews && <HomepageNews nodes={nodes} />}
      {hasEvents && (
        <HomepageEvent
          nodes={nodes}
          prevRef={prevRef}
          nextRef={nextRef}
          setSwiper={setSwiper}
        />
      )}
      {(hasPublications || hasGalleries) && (
        <HomepagePublication nodes={nodes} hasPublications={hasPublications} />
      )}
    </Layout>
  );
}

 async function _getStaticProps(context) {
  const now = formatDateYMD(new Date());

  const bannersParams = new DrupalJsonApiParams();

  bannersParams
    .addInclude(["field_image"])
    .addFilter("status", 1)
    .addSort("created", "DESC")
    .addPageLimit(5);

  const banners = await getResourceCollection("node--banner", {
    params: bannersParams.getQueryObject(),
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  });

  const newsesParams = new DrupalJsonApiParams();

  newsesParams
    .addInclude(["field_image", "field_type"])
    .addFilter("status", 1)
    .addFilter("promote", 1)
    .addFilter("langcode", context.locale)
    .addSort("sticky", "DESC")
    .addSort("created", "DESC")
    .addPageLimit(5);

  const newses = await getResourceCollection("node--news", {
    params: newsesParams.getQueryObject(),
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  });

  const eventsParams = new DrupalJsonApiParams();

  eventsParams
    .addGroup("forthcoming", "OR")
    .addInclude(["field_type"])
    .addFilter("status", 1)
    .addFilter("field_date_end", now, ">=", "forthcoming")
    .addFilter("field_date_start", now, ">=", "forthcoming")
    .addFilter("langcode", context.locale)
    .addPageLimit(6)
    .addSort("sticky", "DESC")
    .addSort("field_date_start", "ASC");

  const events = await getResourceCollection("node--event", {
    params: eventsParams.getQueryObject(),
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  });

  const publicationsParams = new DrupalJsonApiParams();

  publicationsParams
    .addInclude(["field_image", "field_file"])
    .addFilter("status", 1)
    .addFilter("langcode", context.locale)
    .addSort("created", "DESC")
    .addSort("title", "ASC")
    .addPageLimit(1);

  const publications = await getResourceCollection("node--publication", {
    params: publicationsParams.getQueryObject(),
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  });

  const galleriesParams = new DrupalJsonApiParams();

  galleriesParams
    .addInclude(["field_images"])
    .addFilter("status", 1)
    .addFilter("langcode", context.locale)
    .addSort("created", "DESC")
    .addPageLimit(2);

  const galleries = await getResourceCollection("node--gallery", {
    params: galleriesParams.getQueryObject(),
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  });

  return {
    props: {
      globals: await getGlobals(context),
      nodes: {
        banners,
        newses,
        events,
        publications,
        galleries,
      },
    },
    revalidate: 30,
  };
}


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  